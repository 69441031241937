import React from "react";

import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import Footer from "../components/Footer";

export default function Home() {
  return (
    <div className="md:h-screen flex flex-col justify-between md:overflow-hidden">
      <div className="">
        <Navbar />
        <Hero />
      </div>
      <Footer />
    </div>
  );
}
