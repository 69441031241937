import React from "react";

function Footer() {
  return (
    <div className="flex flex-row mt-8 m-4 border-t pt-4 font-light gap-2">
      <p>Copyright 2025 Jellybean Lab All Rights Reserved.</p>
      <a href="https://campusjobs.co/">Campus</a>
    </div>
  );
}

export default Footer;
